import React, { useEffect, useState } from "react";
import { Box, Button, Container, FormControl, Grid, InputLabel, LinearProgress, MenuItem, Select, TextField, Typography, useTheme } from "@mui/material"
import { useHistory } from "react-router";
import style from "./style";
import { format, subYears } from "date-fns";
import { validateEmail, validateFields } from "../../utils";
import { Validators } from "../../types";
import { auth, database, firebase } from "../../services/firebase";
import { translateMessageErrorToPTBR } from "../../utils/messagesErrorsFirebase";
import { addEnqueueSnackbar } from "../../components/Notifier";
import { getLoading, setLoading } from "../../utils/loadingState";

interface IData {
  name: string,
  phoneNumber: string,
  emergencyPhoneNumber: string,
  bloodType: string,
  birthday: string,
  email: string,
  password: string,
  passwordConfirm: string,
  address: {
    cep: string,
    number: string,
    complement: string
  }
}

const Register:React.FC = () => {
  const classes = style(useTheme());
  const history = useHistory();
  const [data, setData] = useState<IData>({
    name: '',
    phoneNumber: '',
    emergencyPhoneNumber: '',
    bloodType: '',
    birthday: format(subYears(new Date(), 18), 'yyyy-MM-dd'),
    email: '',
    password: '',
    passwordConfirm: '',
    address: {
      cep: '',
      number: '',
      complement: ''
    }
  });
  const loading = getLoading();
  const [strongDifficultyPassword, setStrongDifficultyPassword] = useState(0);
  const [textsDifficultyPassword, setTextsDifficultyPassword] = useState('');

  useEffect(() => {
    const checkIfEightChar = () => data.password.length >= 8;
    const checkIfOneLowercase = ()  => /[a-z]/.test(data.password);
    const checkIfOneUppercase = ()  => /[A-Z]/.test(data.password);
    const checkIfOneDigit = ()  => /[0-9]/.test(data.password);
    const checkIfOneSpecialChar = ()  => /[~`!@#$%^&*+=\-[\]\\';,/{}|\\":<>?çÇ]/g.test(data.password);

    let strong = 0;
    if(checkIfEightChar()) strong += 20;
    if(checkIfOneLowercase()) strong += 20;
    if(checkIfOneUppercase()) strong += 20;
    if(checkIfOneDigit()) strong += 20;
    if(checkIfOneSpecialChar()) strong += 20;

    const text = () => {
      if(strong === 0) return ''
      if(strong <= 20) return 'Só isso?';
      if(strong <= 40) return 'Vamos lá, você é capaz!';
      if(strong <= 60) return 'Está melhorando';
      if(strong <= 80) return 'Falta pouco...';
      if(strong <= 100) return 'Gostei de ver';
      return '';
    }
    setStrongDifficultyPassword(strong);
    setTextsDifficultyPassword(text())
  }, [data.password]);

  const validators: Validators[] = [
    {
      valid: data.name.length > 5,
      message: 'Nome é um campo obrigatório e deve conter mais de 5 letras',
      target: 'inputName'
    },
    {
      valid: data.phoneNumber.length === 15,
      message: 'Telefone é um campo obrigatório',
      target: 'inputPhoneNumber'
    },
    {
      valid: data.emergencyPhoneNumber.length === 15,
      message: 'Telefone de emergência é um campo obrigatório',
      target: 'inputEmergencyPhoneNumber'
    },
    {
      valid: !!data.bloodType,
      message: 'Tipo sanguíneo é um campo obrigatório',
      target: 'inputBloodType'
    },
    {
      valid: data.address.cep.length === 8,
      message: 'CEP é um campo obrigatório',
      target: 'inputCEP'
    },
    {
      valid: validateEmail(data.email),
      message: 'E-mail é um campo obrigatório',
      target: 'inputEmail'
    },
    {
      valid: new Date(data.birthday).getTime() <= subYears(new Date(), 18).getTime(),
      message: 'Apenas maiores de 18 anos podem se cadastrar',
      target: 'inputBirthday'
    },
    {
      valid: data.password === data.passwordConfirm,
      message: 'As senhas não coincidem',
      target: 'inputPasswordConfirm'
    },
    {
      valid: strongDifficultyPassword === 100,
      message: 'A senha deve conter oito dígitos entre números, letras maiúsculas e minúsculas e caracteres especiais',
      target: 'inputPassword'
    }
  ];

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(!validateFields(validators)){
      return;
    }
    setLoading(true)
    auth.createUserWithEmailAndPassword(
      data.email,
      data.password
    ).then(async result => {
      await database.collection('users').doc(result.user?.uid).set({
        name: data.name,
        phoneNumber: data.phoneNumber.replace(/\D/g, ''),
        emergencyPhoneNumber: data.emergencyPhoneNumber.replace(/\D/g, ''),
        bloodType: data.bloodType,
        birthday: firebase.firestore.Timestamp.fromDate(new Date(data.birthday)),
        address: {
          ...data.address,
          cep: Number(data.address.cep.replace(/\D/g, '')),
          number: Number(data.address.number)
        }
      })
      await result.user?.updateProfile({
        displayName: data.name
      })
      result.user?.sendEmailVerification().then(() => {
        addEnqueueSnackbar({
          message: 'Um e-mail de validação foi enviado. Verifique na sua caixa de entrada ou na caixa de spam.',
          variant: 'success'
        })
        auth.signOut();
        setLoading(false);
        handleGoBack();
      }).catch(error => {
        addEnqueueSnackbar({
          message: translateMessageErrorToPTBR(error.code) ?? error.message,
          variant: 'error'
        })
        setLoading(false)
      })
    }).catch(error => {
      addEnqueueSnackbar({
        message: translateMessageErrorToPTBR(error.code) ?? error.message,
        variant: 'error'
      })
      setLoading(false)
    })
  }

  const handleGoBack = () => history.goBack();

  return (
    <Container>
      <Typography component='h1' variant='h4' className={classes.titlePage}>Registrar</Typography>
      <Grid container alignItems='center' justifyContent='center' direction='column'>
        <Grid item>
          <Box
            component='form'
            onSubmit={handleSubmit}
            noValidate
            autoComplete='off'
          >
            <TextField
              id='inputName'
              variant='standard'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.name && data.name.length <= 5)}
              fullWidth
              label='Nome'
              autoFocus
              value={data.name}
              onChange={evt => setData(prev => ({...prev, name: evt.target.value.trimStart().replace(/\s+/g, ' ').replace(/[^A-zÀ-ú ]/g, '').replace(/[_`´[\]]/g, '').substring(0, 50)}))}
            />
            <TextField
              id='inputPhoneNumber'
              variant='standard'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.phoneNumber && data.phoneNumber.length !== 15)}
              fullWidth
              label='Telefone'
              value={data.phoneNumber}
              onChange={evt => setData(prev => ({...prev, phoneNumber: evt.target.value.replace(/\D/g, '').substring(0,11).replace(/([0-9]{2})([0-9]{5})([0-9]{4})/, "($1) $2-$3")}))}
            />
            <TextField
              id='inputEmergencyPhoneNumber'
              variant='standard'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.emergencyPhoneNumber && data.emergencyPhoneNumber.length !== 15)}
              fullWidth
              label='Telefone de Emergência'
              value={data.emergencyPhoneNumber}
              onChange={evt => setData(prev => ({...prev, emergencyPhoneNumber: evt.target.value.replace(/\D/g, '').substring(0,11).replace(/([0-9]{2})([0-9]{5})([0-9]{4})/, "($1) $2-$3")}))}
            />
            <Grid container spacing={4}>
              <Grid item xs>
                <FormControl
                  className={classes.formControl}
                  required
                  variant='standard'
                >
                  <InputLabel>Tipo Sanguíneo</InputLabel>
                  <Select
                    id='inputBloodType'
                    disabled={loading}
                    value={data.bloodType}
                    onChange={({target}) => setData(prev => ({...prev, bloodType: target.value as string}))}
                  >
                    <MenuItem value='A+'>A+</MenuItem>
                    <MenuItem value='A-'>A-</MenuItem>
                    <MenuItem value='B+'>B+</MenuItem>
                    <MenuItem value='B-'>B-</MenuItem>
                    <MenuItem value='AB+'>AB+</MenuItem>
                    <MenuItem value='AB-'>AB-</MenuItem>
                    <MenuItem value='O+'>O+</MenuItem>
                    <MenuItem value='O-'>O-</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs>
                <TextField
                  id='inputBirthday'
                  variant='standard'
                  disabled={loading}
                  type='date'
                  margin='normal'
                  required
                  error={!data.birthday || new Date(data.birthday).getTime() > subYears(new Date(), 18).getTime()}
                  fullWidth
                  label='Aniversário'
                  value={data.birthday}
                  onChange={evt => setData(prev => ({...prev, birthday: evt.target.value}))}
                />
              </Grid>
            </Grid>
            <Typography component='h1' variant='h6' className={classes.addressLabelSeparator}>
              Endereço
            </Typography>
            <Grid container spacing={4}>
              <Grid item xs>
                <TextField
                  id='inputCEP'
                  variant='standard'
                  type='number'
                  disabled={loading}
                  margin='normal'
                  required
                  error={Boolean(data.address.cep && data.address.cep.length !== 8)}
                  fullWidth
                  label='CEP'
                  value={data.address.cep}
                  onChange={evt => setData(prev => ({...prev, address: {...data.address, cep: evt.target.value.substring(0, 8)}}))}
                />
              </Grid>
              <Grid item xs>
                <TextField
                  type='number'
                  variant='standard'
                  disabled={loading}
                  margin='normal'
                  fullWidth
                  label='Número'
                  value={data.address.number}
                  onChange={evt => setData(prev => ({...prev, address: {...data.address, number: evt.target.value}}))}
                />
              </Grid>
            </Grid>
            <TextField
              disabled={loading}
              variant='standard'
              margin='normal'
              fullWidth
              label='Complemento'
              value={data.address.complement}
              onChange={evt => setData(prev => ({...prev, address: {...data.address, complement: evt.target.value}}))}
            />
            <TextField
              id='inputEmail'
              variant='standard'
              type='email'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.email && !validateEmail(data.email))}
              fullWidth
              label='E-mail'
              autoComplete='username'
              value={data.email}
              onChange={evt => setData(prev => ({...prev, email: evt.target.value}))}
            />
            <TextField
              id='inputPassword'
              variant='standard'
              type='password'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.password && data.password.length < 8)}
              fullWidth
              label='Senha'
              autoComplete='new-password'
              value={data.password}
              onChange={evt => setData(prev => ({...prev, password: evt.target.value}))}
            />
            <Box display='flex' alignItems='center'>
              {strongDifficultyPassword > 0 && (
                <>
                  <Box width='100%' mr={1}>
                    <LinearProgress variant='determinate' value={strongDifficultyPassword} />
                  </Box>
                  <Box>
                    <Typography variant='body2' color='primary' noWrap>
                      {textsDifficultyPassword}
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
            <TextField
              id='inputPasswordConfirm'
              variant='standard'
              type='password'
              disabled={loading}
              margin='normal'
              required
              error={Boolean(data.passwordConfirm && data.password !== data.passwordConfirm)}
              fullWidth
              label='Confirmar senha'
              autoComplete='new-password'
              value={data.passwordConfirm}
              onChange={evt => setData(prev => ({...prev, passwordConfirm: evt.target.value}))}
            />
            <Grid container className={classes.wrapper}>
              <Grid item>
                <Button
                  disabled={loading}
                  variant='contained'
                  onClick={handleGoBack}
                >Voltar</Button>
              </Grid>
              <Grid item>
                <Button
                  disabled={!validateEmail(data.email) || loading}
                  type='submit'
                  fullWidth
                  variant='contained'
                >Confirmar</Button>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Register
