import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from ".";
import { INotifier } from "../types";

const initialState: INotifier[] = [];

const store = createSlice({
  name: 'notifier',
  initialState,
  reducers: {
    addNotifier: (state, {payload}) => {
      state.push({
        ...payload,
        key: payload.key ?? new Date().getTime() + Math.random()
      });
    },
    closeSnackbar: (state, {payload}) => {
      state = state.filter(snack => (
        (payload.dismissAll || snack.key === payload.key)
          ? { ...snack, dismissed: true }
          : { ...snack }
      ))
    },
    removeSnackbar: (state, {payload}:PayloadAction<Number>) => state.filter(item => item.key !== payload)
  }
});

export const { addNotifier, closeSnackbar, removeSnackbar } = store.actions;
export const getNotifier = (state: RootState) => state.notifier;
export default store.reducer;