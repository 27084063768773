import store from "../store";
import { addNotifier } from "../store/Notifier.store";
import { Validators } from "../types";

interface IReturnIfHasErrorInValidator {
  fieldToFocus?: string,
  messages: string[]
}

export const validateFields = (fields:Validators[]):boolean => {
  const returnIfHasError:IReturnIfHasErrorInValidator = {
    messages: []
  };
  const invalid = fields.find(item => !item.valid);
  if(invalid) {
    store.dispatch(addNotifier({
      message: invalid.message,
      options: {
        variant: invalid.messageType ?? 'warning'
      }
    }))
    if(invalid.target)
      document.getElementById(invalid.target)?.focus?.();
    return false;
  }
  fields.forEach(element => {
    if(!element.valid){
      returnIfHasError.messages.push(element.message)
      if(!returnIfHasError.fieldToFocus && element.target)
        element.target && document.getElementById(element.target)?.focus()
    }
  });
  return true;
}

export const validateEmail = (email:string) => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export const onChangeInputData = (event: React.ChangeEvent<HTMLInputElement|HTMLTextAreaElement>, setData: React.Dispatch<React.SetStateAction<any>>) => {
  setData((prev: any) => ({
    ...prev,
    [event.target.name]: event.target.value
  }))
}

export const formatPhoneNumber = (phoneNumber:string|number, regex?:{search?: string|RegExp, replace?: string}) => {
  return phoneNumber.toString().replace(regex?.search ?? /(\d{2})(\d{5})(\d{4})/g, regex?.replace ?? '($1) $2-$3');
}
