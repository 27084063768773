import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, CardContent, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, TextField, Typography, useTheme } from '@mui/material';
import { auth, database } from '../../services/firebase';
import { User } from '../../types';
import { loadCurrentUser } from '../../utils/user';
import * as Icons from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import DialogContext from '../../context/DialogContext';
import { formatPhoneNumber } from '../../utils';
import { addEnqueueSnackbar } from '../../components/Notifier';

interface ITeam {
  uid: string,
  name: string,
  phoneNumber: string,
  emergencyPhoneNumber: string,
  bloodType: string,
  accepted: boolean,
  squad: string,
  guns?: {
    model:string
  }[]
}

const Team: React.FC = () => {
  const theme = useTheme();
  const dialogContext = useContext(DialogContext);
  const [list, setList] = useState<ITeam[]>([]);
  const DEFAULT_DIALOG_NEW_SOLDIER = {
    accepted: false,
    bloodType: '',
    emergencyPhoneNumber: '',
    name: '',
    phoneNumber: '',
    squad: '',
    uid: '',
    guns: []
  }
  const [dialogNewSoldier, setDialogNewSoldier] = useState<{show: boolean, newSoldier: ITeam}>({
    show: false,
    newSoldier: DEFAULT_DIALOG_NEW_SOLDIER
  });

  const { currentUser } = auth;
  const [user, setUser] = useState<User>();

  useEffect(() => {
    loadCurrentUser().then(user => {
      if (user) {
        setUser({...user, email: currentUser?.email ?? ''});
      }
    });
  }, [currentUser]);

  useEffect(() => {
    const query = database.collection('users');
    if(!user?.admin){
      query.where('accepted', '==', true);
    }
    query.get().then(result => {
      const list = result.docs.map(item => ({
        uid: item.id,
        name: item.get('name'),
        phoneNumber: item.get('phoneNumber'),
        emergencyPhoneNumber: item.get('emergencyPhoneNumber'),
        bloodType: item.get('bloodType'),
        accepted: Boolean(item.get('accepted')),
        squad: item.get('squad') ?? '',
        guns: item.get('guns') ?? []
      }));
      const listSorted = list.sort((a, b) => !a.squad ? 0 : a.squad > b.squad ? 1 : -1);
      setList(listSorted);
    })
  }, []); // eslint-disable-line

  const handleShowUserInfo = (soldier:ITeam) => {
    dialogContext.setState({
      show: true,
      title: soldier.name,
      message: (
        <>
          <Typography>Contato: {formatPhoneNumber(soldier.phoneNumber)}</Typography>
          <Typography>Emergência: {formatPhoneNumber(soldier.emergencyPhoneNumber)}</Typography>
          <Typography>Tipo Sanguíneo: {soldier.bloodType}</Typography>
          <Typography>Armas: {soldier.guns?.map(item => item.model).join(', ') ?? ''}</Typography>
        </>
      ),
      cancelButton: {
        text: 'fechar'
      },
      confirmButton: {
        visible: false
      }
    })
  }

  const resetDialogData = () => {
    setDialogNewSoldier({
      show: false,
      newSoldier: DEFAULT_DIALOG_NEW_SOLDIER
    })
  }

  const handleAcceptUser = (newSoldier:ITeam) => setDialogNewSoldier({
    show: true,
    newSoldier
  });

  const handleAcceptNewUser = () => {
    const { newSoldier } = dialogNewSoldier;
    database.collection('users').doc(newSoldier.uid).set({
      accepted: true,
      squad: Number(newSoldier.squad)
    }, {merge: true}).then(() => {
      addEnqueueSnackbar({
        message: `Operador ${newSoldier.name} aceito com sucesso!`,
        variant: 'success'
      });
      setList(list.map(item => item.uid === newSoldier.uid ? {...item, accepted: true, squad: newSoldier.squad} : item))
      resetDialogData();
    }).catch(error => {
      addEnqueueSnackbar({
        message: error.message,
        variant: 'error'
      })
    })
  }

  const openWhatsApp = (number:string) => {
    window.open(`https://wa.me/55${number}`, '_blank');
  }

  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    marginTop: theme.spacing(1),
    ...theme.typography.body2
  }))

  const CardLeftSide = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    marginLeft: theme.spacing(1)
  }));

  const CardRightSide = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'flex-end'
  }));

  const SoldierCard = (item:ITeam, index:number) => {
    return (
      <Grid item key={index} md={3} sm={4} xs={6}>
        <Card>
          <CardContent sx={{display: 'flex', justifyContent: 'space-between', width: '100%', padding: `${theme.spacing(1)} !important`}}>
            <CardLeftSide>
              <Typography>{item.name}</Typography>
              <Typography>{formatPhoneNumber(item.phoneNumber)}</Typography>
            </CardLeftSide>
            <CardRightSide>
              <IconButton onClick={() => openWhatsApp(item.phoneNumber)}>
                <Icons.WhatsApp htmlColor='#00bfa5'/>
              </IconButton>
              <IconButton onClick={() => (item.accepted ? handleShowUserInfo : handleAcceptUser)(item)}>
                {item.accepted ? <Icons.InfoOutlined/> : <Icons.Check/>}
              </IconButton>
            </CardRightSide>
          </CardContent>
        </Card>
      </Grid>
    )
  }

  return (
    <Grid container sx={{marginTop: 'unset'}}>
      <Dialog open={dialogNewSoldier.show}>
        <DialogTitle>Novo operador ({dialogNewSoldier.newSoldier.name})</DialogTitle>
        <DialogContent>
          <TextField
            label='Pelotão'
            value={dialogNewSoldier.newSoldier.squad}
            error={!dialogNewSoldier.newSoldier.squad.length}
            onChange={event => setDialogNewSoldier(prev => ({
              ...prev,
              newSoldier: {
                ...prev.newSoldier,
                squad: event.target.value.replace(/\D/g, '').substring(0, 1)
              }
            }))}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={resetDialogData}>Fechar</Button>
          <Button color='success' onClick={handleAcceptNewUser}>Aceitar</Button>
        </DialogActions>
      </Dialog>
      {user?.admin && !!list.filter(item => !item.squad).length && (
      <Root>
        <Divider sx={{width: '100%'}}>Novos operadores</Divider>
      </Root>)}
      {user?.admin && list.filter(item => !item.squad).map((item, index) => (
        <Grid item key={index} xs={12}>
          <Grid item md={12}>
            <Grid container>
              {list.filter(itemFilter => itemFilter.squad === item.squad).map((item, index) => SoldierCard(item, index))}
            </Grid>
          </Grid>
        </Grid>
      ))}
      {list.filter((item, index, array) => index === 0 || (item.squad && item.squad !== array[index-1].squad)).map((item, index) => (
        <Grid item key={index} xs={12}>
          <Root>
            <Divider sx={{width: '100%'}}>{`${item.squad}º Pelotão`}</Divider>
          </Root>
          <Grid item md={12}>
            <Grid container>
              {list.filter(itemFilter => itemFilter.squad === item.squad).map((item, index) => SoldierCard(item, index))}
            </Grid>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
export default Team;
