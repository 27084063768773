import { createTheme } from "@mui/material/styles";

export default createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#e3e3e3'
    }
  },
  components: {
    MuiTextField: {
      defaultProps: {
        variant: 'standard',
        margin: 'normal',
        fullWidth: true
      }
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
        fullWidth: true
      }
    },
    MuiButton: {
      defaultProps: {
        variant: 'contained',
        fullWidth: true,
        color: 'primary',
        style: {
          fontWeight: 600
        }
      }
    },
    MuiIcon: {
      defaultProps: {
        color: 'primary'
      }
    },
    MuiGrid: {
      defaultProps: {
        spacing: 2
      }
    }
  }
})
