import React, { useState } from 'react';
import { Button, Grid, TextField } from '@mui/material';
import { Training, Validators } from '../../types';
import { onChangeInputData, validateFields } from '../../utils';
import { DateTimePicker } from '@mui/lab';
import { getWeek, isValid, setDay, setHours, setMinutes, setWeek } from 'date-fns';
import { auth, database, firebase } from '../../services/firebase';
import { addEnqueueSnackbar } from '../../components/Notifier';
import { useHistory } from 'react-router-dom';

const CreateTraining:React.FC = () => {
  const DATA_DEFAULT = {
    cancelled: false,
    date: setWeek(setDay(setHours(setMinutes(new Date(), 0), 8), 0), getWeek(new Date())+1),
    description: '',
    doc: '',
    title: ''
  }

  const history = useHistory();
  const [data, setData] = useState<Training>(DATA_DEFAULT);

  const resetData = () => setData(DATA_DEFAULT);

  const validators:Validators[] = [
    {
      valid: !!data.title,
      message: 'Preencha o título',
      target: 'title'
    },
    {
      valid: !!data.description,
      message: 'Preencha a descrição',
      target: 'description'
    },
    {
      valid: isValid(data.date),
      message: 'Preencha data e hora corretamente',
      target: 'date'
    }
  ]

  const handleCreateTrainingButton = () => {
    if(!validateFields(validators)) return;

    const dataToInsert:any = {
      ...data,
      date: firebase.firestore.Timestamp.fromDate(data.date),
      user_created: auth.currentUser?.uid
    };

    delete dataToInsert.doc;

    database.collection('tranningdates').add(dataToInsert).then(() => {
      setData(DATA_DEFAULT)
      addEnqueueSnackbar({
        message: 'Treino criado com sucesso',
        variant: 'success'
      });
      history.push('/');
    }).catch(error => {
      addEnqueueSnackbar({
        message: error.message,
        variant: 'error'
      })
    })
  }

  return (
    <Grid container>
      <Grid item xs={12} sm={6} >
        <TextField
          label='Título'
          name='title'
          id='title'
          required
          value={data.title}
          onChange={event => onChangeInputData(event, setData)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          label='Descrição'
          name='description'
          id='description'
          required
          value={data.description}
          onChange={event => onChangeInputData(event, setData)}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <DateTimePicker
          label='Data e hora'
          value={data.date}
          onChange={date => date && setData(prev => ({...prev, date}))}
          renderInput={props => <TextField required id='date' {...props}/>}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          disabled
          label='Localização (Em breve)'
        />
      </Grid>
      <Grid item xs={6}>
        <Button color='error' onClick={resetData}>Cancelar</Button>
      </Grid>
      <Grid item xs={6}>
        <Button color='success' onClick={handleCreateTrainingButton}>Salvar</Button>
      </Grid>
    </Grid>
  );
};
export default CreateTraining;
