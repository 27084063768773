import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  root: {
    width: '80%'
  },
  wrapper: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  buttonProgress: {
    position: 'absolute'
  }
}))
