import { SnackbarKey, useSnackbar, VariantType } from 'notistack';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import store from '../../store';
import { addNotifier, getNotifier, removeSnackbar } from '../../store/Notifier.store';

interface IAddEnqueueSnackbar {
  message: string,
  variant?: VariantType
}

export const addEnqueueSnackbar = (props:IAddEnqueueSnackbar) => {
  store.dispatch(addNotifier({
    message: props.message,
    options: {
      variant: props.variant ?? 'warning'
    }
  }))
}

const Notifier:React.FC = () => {
  const dispatch = useDispatch();
  const notifications = useSelector(getNotifier);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [displayed, setDisplayed] = useState<SnackbarKey[]>([]);

  const storeDisplayed = (id: SnackbarKey) => {
    setDisplayed([...displayed, id]);
  };

  const removeDisplayed = (id: SnackbarKey) => {
    setDisplayed(displayed.filter(key => id !== key));
  };

  useEffect(() => {
    notifications.forEach(({ key, message, options = {}, dismissed = false }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(message, {
        key,
        ...options,
        onExited: (event, myKey) => {''
          // remove this snackbar from redux store
          dispatch(removeSnackbar(Number(myKey)));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]); // eslint-disable-line

  return null
}
export default Notifier