import React, { useEffect, useRef, useState } from 'react';
import { Card, CardContent, Grid, IconButton, SpeedDial, SpeedDialAction, Typography, useTheme } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { storage } from '../../services/firebase';
import { addEnqueueSnackbar } from '../../components/Notifier';
import { setLoading } from '../../utils/loadingState';
import { loadCurrentUser } from '../../utils/user';
import { User } from '../../types';

interface IFile {
  name:string,
  extension: string,
  url: string
}

const Files:React.FC = () => {
  const theme = useTheme();
  const [list, setList] = useState<IFile[]>([]);
  const [user, setUser] = useState<User>();
  const inputFileImage = useRef<HTMLInputElement>(null);
  const inputFilePDF = useRef<HTMLInputElement>(null);

  const SPEED_DIAL_ACTION_BUTTONS = [
    {
      icon: <Icons.Image/>,
      tooltipTitle: 'Enviar imagem',
      onClick: () => openUploadExplorer('image')
    },
    {
      icon: <Icons.PictureAsPdf/>,
      tooltipTitle: 'Enviar PDF',
      onClick: () => openUploadExplorer('pdf')
    }
  ];

  useEffect(() => {
    setLoading(true);
    storage.ref().child('documents').listAll().then(result => {
      (async () => {
        let list:IFile[] = [];
        for(let i = 0; i < result.items.length; i++){
          const { name, fullPath } = result.items[i]
          const extension = fullPath.split('.').pop() ?? '';
          const url = await result.items[i].getDownloadURL()
          list.push({
            name,
            extension,
            url
          })
        }
        setList(list)
      })()
    }).catch(error => {
      addEnqueueSnackbar({
        message: error.message,
        variant: 'error'
      })
    }).finally(() => {
      setLoading(false)
    });
    loadCurrentUser().then(result => setUser(result));
  }, []);

  const downloadFile = (url:string) => {
    window.open(url)
  }

  const openUploadExplorer = (type:string) => (type === 'image' ? inputFileImage : inputFilePDF).current?.click();

  const uploadFile = (event:HTMLInputElement) => {
    const file = event.files?.[0];
    if (!file) return;
    setLoading(true)
    storage.ref(`documents/${file.name}`).put(file).then(async result => {
      const url = await result.ref.getDownloadURL();
      setList([
        ...list,
        {
          extension: file.name.split('.').pop() ?? '',
          name: file.name,
          url
        }
      ]);
      addEnqueueSnackbar({
        message: 'Arquivo salvo com sucesso',
        variant: 'success'
      })
    }).catch(error => {
      addEnqueueSnackbar({
        message: error.message,
        variant: 'error'
      })
    }).finally(() => {
      inputFileImage.current?.value && (inputFileImage.current.value = '');
      inputFilePDF.current?.value && (inputFilePDF.current.value = '');
      setLoading(false);
    });
  }

  return (
    <>
      <Grid container sx={{marginTop: theme.spacing(1)}}>
        {!list.length && (
          <Grid item xs display='flex' justifyContent='center'>
            <Typography
              component='h2'
              variant='h4'
            >
              Não há arquivos
            </Typography>
          </Grid>
        )}
        {list.map((file, index) => (
          <Grid key={index} item>
            <Card>
              <Grid container>
                <Grid item xs>
                  <CardContent>
                    <Typography>{file.name}</Typography>
                  </CardContent>
                </Grid>
                <Grid item display='flex' alignItems='center'>
                  <IconButton onClick={() => downloadFile(file.url)}>
                    <Icons.Download/>
                  </IconButton>
                </Grid>
              </Grid>
            </Card>
          </Grid>
        ))}
      </Grid>
      {user?.admin && (
        <SpeedDial
          ariaLabel='Enviar arquivos'
          sx={{ position: 'absolute', bottom: theme.spacing(2), right: theme.spacing(2) }}
          icon={<Icons.FileUpload/>}
        >
          <input hidden type='file' ref={inputFileImage} name='file' accept='image/png, image/jpeg' onChange={event => uploadFile(event.target)} />
          <input hidden type='file' ref={inputFilePDF} name='file' accept='application/pdf' onChange={event => uploadFile(event.target)} />
          {SPEED_DIAL_ACTION_BUTTONS.map((item, index) => (
            <SpeedDialAction
              key={index}
              tooltipPlacement='left'
              {...item}
            />
          ))}
        </SpeedDial>
      )}
    </>
  )
}
export default Files;
