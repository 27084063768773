import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme:Theme) => ({
  ul: {
    margin: 0,
    listStyle: 'none',
    paddingLeft: theme.spacing(1)
  }
}))
