import React, { useState } from 'react';
import { Box, Button, Container, Grid, Link, TextField, Typography, useTheme } from '@mui/material';
import logo from '../../assets/icon.png'
import style from './style';
import { Link as LinkRouter } from 'react-router-dom'
import { auth } from '../../services/firebase';
import { translateMessageErrorToPTBR } from '../../utils/messagesErrorsFirebase';
import { validateEmail } from '../../utils';
import { addEnqueueSnackbar } from '../../components/Notifier';
import { setLoading } from '../../utils/loadingState';

interface IData {
  email: string,
  password: string
}

const Login:React.FC = () => {
  const theme = useTheme()
  const classes = style();
  const [data, setData] = useState<IData>({
    email: '',
    password: ''
  });

  const signIn = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);
    if(validateEmail(data.email) && data.password.length > 5){
      auth.signInWithEmailAndPassword(data.email, data.password).then(result => {
        setLoading(false)
        if(!result.user?.emailVerified){
          auth.signOut();
          addEnqueueSnackbar({
            message: 'E-mail não verificado. Verifique na sua caixa de spam.'
          });
        }
      }).catch(error => {
        addEnqueueSnackbar({
          message: translateMessageErrorToPTBR(error.code) ?? error.message
        })
        setLoading(false)
      })
    }else{
      setLoading(false);
    }
  }

  return (
    <Container
      sx={{
        height: '100vh',
        display: 'flex'
      }}
    >
      <Grid container alignItems='center' justifyContent='center' direction='column'>
        <Grid item style={{height: '10wh'}}>
          <img src={logo} alt='CIAPE' className={classes.image}/>
        </Grid>
        <Typography component='h1' variant='h5' color='primary'>
          Entrar
        </Typography>
        <Grid item>
          <Box
            component='form'
            onSubmit={signIn}
            noValidate
            autoComplete='off'
          >
            <TextField
              error={Boolean(data.email && !validateEmail(data.email))}
              type='email'
              required
              label='E-mail'
              autoFocus
              autoComplete='username'
              value={data.email}
              onChange={evt => setData(prev => ({...prev, email: evt.target.value}))}
            />
            <TextField
              error={Boolean(data.password && data.password.length <= 5)}
              type='password'
              required
              label='Senha'
              autoComplete='current-password'
              value={data.password}
              onChange={evt => setData(prev => ({...prev, password: evt.target.value}))}
            />
            <Button
              type='submit'
              sx={{
                margin: theme.spacing(3, 0, 2)
              }}
              disabled={!validateEmail(data.email) || data.password.length <= 5}
            >
              Entrar
            </Button>
          </Box>
          <Grid container justifyContent='space-between'>
            <Grid item>
              <Link to="Register" variant="body2" component={LinkRouter}>
                Registrar-se
              </Link>
            </Grid>
            <Grid item>
              <Link to="ForgotPassword" variant="body2" component={LinkRouter}>
                Esqueceu a Senha
              </Link>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}
export default Login
