import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, Grid, TextField, Typography } from '@mui/material';
import { DatePicker, DateTimePicker } from '@mui/lab';
import DialogContext from '../../context/DialogContext';
import { addMonths, format, lastDayOfMonth, setDate, subMonths } from 'date-fns';
import { Training } from '../../types';
import { database, firebase } from '../../services/firebase';
import { setLoading } from '../../utils/loadingState';
import isValid from 'date-fns/isValid';
import { addEnqueueSnackbar } from '../../components/Notifier';
import { onChangeInputData } from '../../utils';

const ManageTrainings: React.FC = () => {
  const DIALOG_DATA_DEFAULT: Training = {
    cancelled: false,
    date: new Date(),
    description: '',
    doc: '',
    title: ''
  }
  const dialogContext = useContext(DialogContext);
  const [dateFilter, setDateFilter] = useState({
    initial: setDate(new Date(), 1),
    final: setDate(new Date(), lastDayOfMonth(new Date()).getDate())
  });
  const [dataGrid, setDataGrid] = useState<Training[]>([]);
  const [dialogData, setDialogData] = useState<Training>(DIALOG_DATA_DEFAULT);

  useEffect(() => filter(), [dateFilter]); // eslint-disable-line

  useEffect(() => {
    if (!dialogContext.state.show && dialogData.title) {
      dialogContext.setState({
        show: true,
        title: dialogData.title,
        message: (
          <Grid container>
            <Grid item sm={6}>
              <DateTimePicker
                disabled={dialogData.date < new Date() || dialogData.cancelled}
                label='Data e hora'
                value={dialogData.date}
                onChange={value => value && setDialogData(prev => ({...prev, date: value}))}
                renderInput={props => <TextField required {...props}/>}
                InputProps={{ readOnly: true }}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                disabled={dialogData.date < new Date() || dialogData.cancelled}
                label='Título'
                name='title'
                required
                value={dialogData.title}
                onChange={event => onChangeInputData(event, setDialogData)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                disabled={dialogData.date < new Date() || dialogData.cancelled}
                label='Descrição'
                name='description'
                required
                value={dialogData.description}
                onChange={event => onChangeInputData(event, setDialogData)}
              />
            </Grid>
            <Grid item sm={6}>
              <TextField
                disabled
                label='Localização (Em breve)'
                required
              />
            </Grid>
          </Grid>
        ),
        confirmButton: {
          text: 'salvar',
          props: {
            disabled: dialogData.date < new Date() || dialogData.cancelled,
            color: 'success'
          }
        },
        thirdyButton: {
          text: 'fechar'
        },
        cancelButton: {
          text: 'cancelar treino',
          props: {
            disabled: dialogData.date < new Date() || dialogData.cancelled,
            color: 'error'
          },
          callback: closeModal => {
            const confirm = window.confirm(`Você deseja cancelar o treino/jogo '${dialogData.title}'?\nEssa ação não poderá ser desfeita.`);
            if (confirm) {
              database.collection('tranningdates').doc(dialogData.doc).set({
                cancelled: true
              }, {merge: true}).then(() => {
                addEnqueueSnackbar({
                  message: 'Treino/Jogo cancelado com sucesso',
                  variant: 'success'
                });
                closeModal();
                setDataGrid(dataGrid.map(data => data.doc === dialogData.doc ? {...data, cancelled: true} : data))
              }).catch(error => {
                addEnqueueSnackbar({
                  message: error.message,
                  variant: 'error'
                });
              })
            }
          }
        },
        onDismiss: (hide) => {
          setDialogData(DIALOG_DATA_DEFAULT);
          hide();
        }
      })
    }
  }, [dialogData]); // eslint-disable-line

  const filter = () => {
    if (!isValid(dateFilter.initial) || !isValid(dateFilter.final)) return;
    setLoading(true);
    database
      .collection('tranningdates')
      .where('date', '>=', dateFilter.initial)
      .where('date', '<=', dateFilter.final)
      .get()
      .then(result => {
        setDataGrid(result.docs.map(item => ({
          date: new firebase.firestore.Timestamp(item.get('date').seconds, item.get('date').nanoseconds).toDate(),
          description: item.get('description'),
          title: item.get('title'),
          cancelled: item.get('cancelled'),
          doc: item.id
        })))
      }).catch(error => {
        addEnqueueSnackbar({
          variant: 'error',
          message: error.message
        })
      }).finally(() => setLoading(false));
  }

  const selectTraining = (training:Training) => setDialogData(training);

  return (
    <>
      <Grid container>
        <Grid item xs>
          <DatePicker
            value={dateFilter.initial}
            onChange={value => value && setDateFilter(prev => ({...prev, initial: value}))}
            renderInput={props => <TextField required {...props}/>}
            minDate={subMonths(new Date(), 4)}
            maxDate={new Date()}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={1} alignItems='center' display='flex' justifyContent='center'>
          <Typography variant='subtitle2' component='p'>Até</Typography>
        </Grid>
        <Grid item xs>
          <DatePicker
            value={dateFilter.final}
            onChange={value => value && setDateFilter(prev => ({...prev, final: value}))}
            renderInput={props => <TextField required {...props}/>}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 4)}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={1}>
        {dataGrid.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} lg={3}>
            <Box>
              <Card variant='outlined'>
                <CardActionArea onClick={() => selectTraining(item)}>
                  <CardContent>
                    <Grid container display='flex' justifyContent='space-between'>
                      <Grid item>
                        <Typography variant='body1' component='div' display='flex' alignItems='center'>
                          {item.title}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography variant='body1' component='div' display='flex' alignItems='center'>
                          {format(item.date, 'dd/MM/yyyy')}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default ManageTrainings;
