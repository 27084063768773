import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import store from './store';
import { CssBaseline } from '@mui/material';
import { DialogContextProvider } from './context/DialogContext';
import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import ptBR from 'date-fns/locale/pt-BR';

ReactDOM.render(
  <React.StrictMode>
    <Provider {...{store}}>
      <ThemeProvider {...{theme}}>
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={ptBR}>
          <DialogContextProvider>
            <CssBaseline/>
            <SnackbarProvider preventDuplicate={false}>
              <App />
            </SnackbarProvider>
          </DialogContextProvider>
        </LocalizationProvider>
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
