import React, { useState } from 'react';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  AppBar,
  Box,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
  useTheme
} from '@mui/material';
import * as Icons from '@mui/icons-material';
import screens from '../../screens';
import style from "./style";
import { auth } from '../../services/firebase';
import { User } from '../../types';

const Sidebar:React.FC<{user?: User}> = ({user}) => {
  const classes = style(useTheme());
  const [open, setOpen] = useState(false);
  const [showMenu, setShowMenu] = useState(false);
  const history = useHistory();
  const location = useLocation();

  const handleOpenDrawer = () => setOpen(true);
  const handleCloseDrawer = () => setOpen(false);

  const handleToggleShowMenu = () => setShowMenu(prev => !prev);
  const handleCloseShowMenu = () => setShowMenu(false);

  const handleLogOut = () => {
    history.push('/');
    auth.signOut();
  }
  const handleGoToProfile = () => history.push('/profile');

  const screensFiltered = screens.filter(screen => screen.onlyAdmin ? user?.admin : true);

  return (
    <>
      <AppBar>
        <Toolbar style={{justifyContent: 'space-between'}}>
          <IconButton
            onClick={open ? handleCloseDrawer : handleOpenDrawer}
          >
            <Icons.Menu/>
          </IconButton>
          <Typography component='h1' variant='h5'>
            {screensFiltered.find(screen => screen.path === location.pathname)?.name.toUpperCase() ?? ''}
          </Typography>
          <IconButton
            id='menu-user-button'
            onClick={handleToggleShowMenu}
          >
            <Icons.AccountCircle />
          </IconButton>
          <Menu
            anchorEl={document.getElementById('menu-user-button')}
            open={showMenu}
            onClose={handleCloseShowMenu}
          >
            <MenuItem onClick={() => {
              handleCloseShowMenu();
              handleGoToProfile();
            }}>Editar Perfil</MenuItem>
            <Divider/>
            <MenuItem onClick={() => {
              handleCloseShowMenu();
              handleLogOut();
            }}>Sair</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Drawer
        open={open}
        onClose={handleCloseDrawer}
      >
        <Box
          className={classes.box}
          sx={{width: 250}}
          role='presentation'
        >
          <List>
            {screensFiltered.map((item, index) => (
              <ListItem
                button
                key={index}
                component={NavLink}
                to={item.path}
                exact
                activeStyle={{
                  fontStyle: 'italic'
                }}
                onClick={handleCloseDrawer}
              >
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItem>
            ))}
          </List>
          <div className={classes.boxBottom}>
            <Typography component='p'>
              v{require('../../../package.json').version}
            </Typography>
            <IconButton onClick={handleLogOut}>
              <Icons.Logout/>
            </IconButton>
          </div>
        </Box>
      </Drawer>
    </>
  )
}
export default Sidebar;
