import React, { useEffect, useState } from 'react';
import { Container, useTheme } from '@mui/material';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import Loader from './components/Loader';
import Notifier from './components/Notifier';
import Sidebar from './components/Sidebar';
import screens from './screens';
import ForgotPassword from './screens/ForgotPassword';
import Login from './screens/Login';
import NotFound from './screens/NotFound';
import Register from './screens/Register';
import { auth } from './services/firebase';
import style from './App.style';
import { setLoading } from './utils/loadingState';
import DialogComp from './components/DialogComp';
import { User } from './types';
import { loadCurrentUser } from './utils/user';

const App:React.FC = () => {
  const [logged, setLogged] = useState(false);
  const classes = style(useTheme());
  const [user, setUser] = useState<User>();
  useEffect(() => {
    auth.onAuthStateChanged(result => {
      if(result){
        if(!result.emailVerified){
          return;
        }else{
          loadCurrentUser().then(result => {
            setUser(result);
          }).finally(() => {
            setLogged(true);
            setLoading(false)
          })
        }
      }else{
        setLogged(false)
        setLoading(false)
      }
    })
  }, []) // eslint-disable-line

  return (
    <>
      <Loader/>
      <Notifier/>
      <DialogComp/>
      <Router>
      {!logged ? (
        <Switch>
          <Route path='/ForgotPassword' exact component={ForgotPassword} />
          <Route path='/Register' exact component={Register} />
          <Route path='/' component={Login} />
        </Switch>
      ) : (
        <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'row'}}>
          <Sidebar {...{user}}/>
          <Container className={classes.container}>
            <Switch>
              {screens.filter(screen => screen.onlyAdmin ? user?.admin : true).map((item, index) => (
                <Route
                  key={index}
                  exact
                  {...item}
                />
              ))}
              <Route component={NotFound} />
            </Switch>
          </Container>
        </div>
      )}
      </Router>
    </>
  );
}

export default App;
