import React, { useEffect, useState } from 'react';
import { Button, Chip, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Fab, Grid, MenuItem, SvgIcon, TextField, useTheme } from '@mui/material';
import * as Icons from '@mui/icons-material';
import { auth, database } from '../../services/firebase';
import { User, Validators } from '../../types';
import { loadCurrentUser } from '../../utils/user';
import style from './style';
import NumberFormat from 'react-number-format';
import { onChangeInputData, validateFields } from '../../utils';
import { addEnqueueSnackbar } from '../../components/Notifier';

const Profile:React.FC = () => {
  const theme = useTheme();
  const classes = style(theme);
  const [data, setData] = useState<User>({
    accepted: true,
    address: {
      cep: 0,
      number: 0,
    },
    admin: false,
    birthday: new Date(),
    bloodType: '',
    email: '',
    emergencyPhoneNumber: 0,
    guns: [],
    name: '',
    phoneNumber: 0,
    squad: 0
  });
  const [dialogNewGun, setDialogNewGun] = useState({
    show: false,
    type: 'aeg',
    model: ''
  });
  const [isEditing, setIsEditing] = useState(false);
  const { currentUser } = auth;
  useEffect(() => {
    loadCurrentUser().then(user => {
      if (user) {
        setData({...user, email: currentUser?.email ?? ''});
      }
    });
  }, [currentUser]);

  const validators: Validators[] = [
    {
      valid: data.phoneNumber.toString().replace(/\D/g, '').length === 11,
      message: 'Digite o telefone corretamente',
      target: 'phoneNumber'
    },
    {
      valid: data.emergencyPhoneNumber.toString().replace(/\D/g, '').length === 11,
      message: 'Digite o telefone corretamente',
      target: 'emergencyPhoneNumber'
    },
    {
      valid: !!data.bloodType,
      message: 'Informe o tipo sanguíneo'
    },
    {
      valid: Boolean(
        data.guns?.some(item => item.type === 'pistol') &&
        data.guns?.some(item => item.type === 'aeg' || item.type === 'dmr' || item.type === 'sniper')
      ),
      message: 'Informe ao menos uma arma primaria e uma secundária'
    }
  ];

  const handleClickEditButton = () => {
    if (isEditing) {
      if (!validateFields(validators)) return;
      const dateToUpdate = {
        phoneNumber: Number(data.phoneNumber.toString().replace(/\D/g, '')),
        emergencyPhoneNumber: Number(data.emergencyPhoneNumber.toString().replace(/\D/g, '')),
        bloodType: data.bloodType,
        guns: data.guns
      }
      database.collection('users').doc(currentUser?.uid).set(dateToUpdate, {merge: true}).then(() => {
        addEnqueueSnackbar({
          message: 'Alteração realizada com sucesso'
        });
      }).finally(() => {
        setIsEditing(false)
      })
    } else {
      setIsEditing(true);
    }
  }

  const deleteGun = (model:string) => {
    setData(prev => ({
      ...prev,
      guns: prev.guns.filter(item => item.model !== model)
    }))
  }

  const handleOpenDialogNewGun = () => setDialogNewGun(prev => ({...prev, show: true}));
  const handleCloseDialogNewGun = () => setDialogNewGun({model: '', show: false, type: 'aeg'});

  const handleButtonSaveGun = () => {
    setData(prev => ({
      ...prev,
      guns: [
        ...data.guns,
        {
          model: dialogNewGun.model,
          type: dialogNewGun.type
        }
      ]
    }));
    handleCloseDialogNewGun();
  }

  const iconGun = (key:string) => {
    switch (key) {
      case 'pistol':
        return <SvgIcon>
          <path d="M7,5H23V9H22V10H16A1,1 0 0,0 15,11V12A2,2 0 0,1 13,14H9.62C9.24,14 8.89,14.22 8.72,14.56L6.27,19.45C6.1,19.79 5.76,20 5.38,20H2C2,20 -1,20 3,14C3,14 6,10 2,10V5H3L3.5,4H6.5L7,5M14,12V11A1,1 0 0,0 13,10H12C12,10 11,11 12,12A2,2 0 0,1 10,10A1,1 0 0,0 9,11V12A1,1 0 0,0 10,13H13A1,1 0 0,0 14,12Z"/>
        </SvgIcon>
      case 'aeg':
        return <SvgIcon>
          <path d="M14,22H10V21H14V22M13,10V7H11V10L10,11.5V20H14V11.5L13,10M12,2C12,2 11,3 11,5V6H13V5C13,5 13,3 12,2M8,22H4V21H8V22M7,10V7H5V10L4,11.5V20H8V11.5L7,10M6,2C6,2 5,3 5,5V6H7V5C7,5 7,3 6,2M20,22H16V21H20V22M19,10V7H17V10L16,11.5V20H20V11.5L19,10M18,2C18,2 17,3 17,5V6H19V5C19,5 19,3 18,2Z"/>
        </SvgIcon>
      case 'dmr':
        return <Icons.GpsNotFixed/>
      case 'sniper':
        return <Icons.GpsFixed/>
      default:
        return <Icons.ModeStandby/>
    }
  }

  return (
    <>
      <Dialog
        open={dialogNewGun.show}
        onClose={handleCloseDialogNewGun}
      >
        <DialogTitle>Adicionar arma</DialogTitle>
        <DialogContent>
          <Container>
            <TextField
              required
              select
              label='Tipo'
              value={dialogNewGun.type}
              onChange={event => setDialogNewGun(prev => ({...prev, type: event.target.value}))}
            >
              <MenuItem value='aeg'>Aeg</MenuItem>
              <MenuItem value='pistol'>Pistola</MenuItem>
              <MenuItem value='dmr'>DMR</MenuItem>
              <MenuItem value='sniper'>Sniper</MenuItem>
            </TextField>
            <TextField
              required
              label='Modelo'
              value={dialogNewGun.model}
              onChange={event => setDialogNewGun(prev => ({...prev, model: event.target.value}))}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogNewGun} color='error'>Cancelar</Button>
          <Button onClick={handleButtonSaveGun}>Salvar</Button>
        </DialogActions>
      </Dialog>
      <Grid container>
        <Grid item sm={6} xs={12}>
          <TextField
            disabled
            label='Nome'
            autoComplete='username'
            value={data.name}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TextField
            disabled
            label='E-mail'
            autoComplete='email'
            value={data.email}
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <NumberFormat
            id='phoneNumber'
            customInput={TextField}
            disabled={!isEditing}
            required={isEditing}
            label='Telefone'
            autoComplete='phone'
            value={data.phoneNumber}
            onValueChange={({value}) => setData(prev => ({...prev, phoneNumber: value ? Number(value) : NaN}))}
            format='(##) #####-####'
            mask='_'
          />
        </Grid>
        <Grid item sm={6} xs={6}>
          <NumberFormat
            id='emergencyPhoneNumber'
            customInput={TextField}
            disabled={!isEditing}
            required={isEditing}
            label='Telefone de Emergência'
            autoComplete='phone'
            value={data.emergencyPhoneNumber}
            onValueChange={({value}) => setData(prev => ({...prev, emergencyPhoneNumber: value ? Number(value) : NaN}))}
            format='(##) #####-####'
            mask='_'
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            disabled={!isEditing}
            required={isEditing}
            label='Tipo sanguíneo'
            name='bloodType'
            onChange={event => onChangeInputData(event, setData)}
            select
            value={data.bloodType}
          >
            <MenuItem value='A+'>A+</MenuItem>
            <MenuItem value='A-'>A-</MenuItem>
            <MenuItem value='B+'>B+</MenuItem>
            <MenuItem value='B-'>B-</MenuItem>
            <MenuItem value='AB+'>AB+</MenuItem>
            <MenuItem value='AB-'>AB-</MenuItem>
            <MenuItem value='O+'>O+</MenuItem>
            <MenuItem value='O-'>O-</MenuItem>
          </TextField>
        </Grid>
      </Grid>
      <Divider>Armas</Divider>
      <Grid container>
        <Grid item xs={12} display='flex' justifyContent='center' flexWrap='wrap'>
          {data.guns.map((gun, index) => (
            <Chip
              key={index}
              className={classes.chip}
              label={gun.model}
              onDelete={isEditing ? () => deleteGun(gun.model) : undefined}
              sx={{
                margin: 1
              }}
              icon={iconGun(gun.type)}
            />
          ))}
          {isEditing && <Fab
            variant='circular'
            size='small'
            onClick={handleOpenDialogNewGun}
            >
              <Icons.Add/>
            </Fab>
          }
        </Grid>
      </Grid>
      <Divider/>
      <Button
        sx={{
          margin: theme.spacing(3, 0, 2)
        }}
        color={isEditing ? 'success' : undefined}
        // disabled={!validateEmail(data.email) || data.password.length <= 5}
        onClick={handleClickEditButton}
      >
        {isEditing ? 'Salvar' : 'Editar'}
      </Button>
    </>
  )
}
export default Profile
