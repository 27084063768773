import { auth, database, firebase } from '../services/firebase';
import { User } from '../types';

export const loadCurrentUser = async () => {
  const { currentUser } = auth;
  const userSelected = await database.collection('users').doc(currentUser?.uid).get();
  const user:User = {
    accepted: userSelected.get('accepted'),
    address: {
      cep: userSelected.get('cep'),
      complement: userSelected.get('complement'),
      number: userSelected.get('number')
    },
    admin: userSelected.get('admin'),
    birthday: new firebase.firestore.Timestamp(userSelected.get('birthday').seconds, userSelected.get('birthday').nanoseconds).toDate(),
    bloodType: userSelected.get('bloodType'),
    email: currentUser?.email ?? '',
    emergencyPhoneNumber: userSelected.get('emergencyPhoneNumber'),
    guns: userSelected.get('guns'),
    name: userSelected.get('name'),
    phoneNumber: userSelected.get('phoneNumber'),
    squad: userSelected.get('squad')
  }
  return user;
}
