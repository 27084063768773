import { Button, CircularProgress, Container, Grid, TextField } from "@mui/material"
import { useState } from "react";
import { useHistory } from "react-router";
import { addEnqueueSnackbar } from "../../components/Notifier";
import { auth } from "../../services/firebase";
import { validateEmail } from "../../utils";
import { getLoading, setLoading } from "../../utils/loadingState";
import { translateMessageErrorToPTBR } from "../../utils/messagesErrorsFirebase";
import style from "./style";

const ForgotPassword:React.FC = () => {
  const classes = style();
  const history = useHistory();
  const loading = getLoading();

  const [email, setEmail] = useState('');
  const handleSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setLoading(true);
    if(validateEmail(email)){
      setLoading(true)
      auth.sendPasswordResetEmail(email).then(() => {
        addEnqueueSnackbar({
          message: 'E-mail enviado para redefinir a senha',
          variant: 'success'
        })
        handleGoBack();
      }).catch(error => {
        addEnqueueSnackbar({
          message: translateMessageErrorToPTBR(error.code) ?? error.message,
          variant: 'error'
        })
      }).finally(() => {
        setLoading(false)
      })
    }
  }

  const handleGoBack = () => history.goBack();

  return (
    <Container>
      <Grid container alignItems='center' justifyContent='center' direction='column'>
        <Grid item className={classes.root}>
          <form onSubmit={handleSubmit}>
            <TextField
              disabled={loading}
              variant='standard'
              margin='normal'
              error={Boolean(email && !validateEmail(email))}
              fullWidth
              label='E-mail'
              autoFocus
              value={email}
              onChange={evt => setEmail(evt.target.value)}
            />
            <Grid container className={classes.wrapper}>
              <Grid item>
                <Button
                  variant='contained'
                  onClick={handleGoBack}
                >Voltar</Button>
              </Grid>
              <Grid item>
                <div className={classes.wrapper}>
                  <Button
                    disabled={!validateEmail(email) || loading}
                    type='submit'
                    fullWidth
                    variant='contained'
                  >Recuperar</Button>
                  {loading && <CircularProgress className={classes.buttonProgress} color="primary" size={24} />}
                </div>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Container>
  )
}
export default ForgotPassword
