import React from "react";
import * as Icons from '@mui/icons-material';
import Dashboard from "./Dashboard";
import Profile from "./Profile";
import CreateTraining from "./CreateTraining";
import ListTrainings from "./ListTrainings";
import ManageTrainings from "./ManageTrainings";
import Team from "./Team";
import Files from "./Files";

interface Props {
  path: string
  name: string
  icon: JSX.Element
  component: React.FC
  onlyAdmin?: boolean
}

const screens: Props[] = [
  {
    path: '/',
    name: 'Dashboard',
    icon: <Icons.Home />,
    component: Dashboard
  },
  {
    path: '/profile',
    name: 'Perfil',
    icon: <Icons.Person />,
    component: Profile
  },
  {
    path: '/createtraining',
    name: 'Criar Treino/Jogo',
    icon: <Icons.Add/>,
    component: CreateTraining,
    onlyAdmin: true
  },
  {
    path: '/listtrainings',
    name: 'Treinos/Jogos',
    icon: <Icons.List/>,
    component: ListTrainings
  },
  {
    path: '/managetrainings',
    name: 'Gerenciar Treinos/Jogos',
    icon: <Icons.Edit/>,
    component: ManageTrainings,
    onlyAdmin: true
  },
  {
    path: '/team',
    name: 'Equipe',
    icon: <Icons.Group/>,
    component: Team
  },
  {
    path: '/files',
    name: 'Arquivos',
    icon: <Icons.InsertDriveFile/>,
    component: Files
  }
]
export default screens;
