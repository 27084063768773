import React, { useContext, useEffect, useState } from 'react';
import { Card, CardActionArea, CardContent, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { Training } from '../../types';
import { format } from 'date-fns';
import { auth, database, firebase } from '../../services/firebase';
import { setLoading } from '../../utils/loadingState';
import * as Icons from '@mui/icons-material'
import { addEnqueueSnackbar } from '../../components/Notifier';
import DialogContext from '../../context/DialogContext';

interface _Training extends Training {
  isConfirmed: boolean
}

const Dashboard:React.FC = () => {
  const theme = useTheme();
  const dialogContext = useContext(DialogContext);
  const [trainings, setTrainings] = useState<_Training[]>([]);
  useEffect(() => {
    const snapshot = database.collection('tranningdates').where('date', '>=', new Date()).onSnapshot(result => {
      setTrainings(result.docs.map(item => ({
        doc: item.id,
        date: new firebase.firestore.Timestamp(item.get('date').seconds, item.get('date').nanoseconds).toDate(),
        isConfirmed: item.get('users_confirmed')?.some((item:any) => item === auth.currentUser?.uid),
        description: item.get('description'),
        title: item.get('title'),
        cancelled: item.get('cancelled')
      })))
      setLoading(false)
    });
    return () => {
      snapshot();
      setLoading(false);
    }
  }, []);

  const askConfirmation = (training:_Training) => {
    dialogContext.setState({
      show: true,
      title: training.title,
      message: training.description,
      confirmButton: {
        callback: () => handleAcceptOrRejectTranning(training.doc, !training.isConfirmed),
        text: training.isConfirmed ? 'Recusar' : 'Confirmar'
      }
    })
  }

  const handleAcceptOrRejectTranning = (doc:string, confirmed:boolean) => {
    dialogContext.close();
    const item = trainings.find(item => item.doc === doc);
    if((confirmed && item?.isConfirmed) || (!confirmed && !item?.isConfirmed)) return;
    database.collection('tranningdates').doc(doc).set({
      users_confirmed: firebase.firestore.FieldValue[confirmed ? 'arrayUnion' : 'arrayRemove'](auth.currentUser?.uid)
    }, {merge: true}).then(() => {
      addEnqueueSnackbar({
        variant: 'success',
        message: `Presença ${confirmed ? 'confirmada' : 'recusada'} no evento.`
      })
      setTrainings(trainings.map(item => item.doc === doc ? {...item, isConfirmed: confirmed} : item))
    }).catch(err => {
      addEnqueueSnackbar({
        variant: 'error',
        message: err.message
      })
    });
  }

  const handleShowUsersConfirmed = (training:_Training) => {
    database.collection('tranningdates').doc(training.doc).get().then(async result => {
      const team = result.get('users_confirmed');
      if(Array.isArray(team) && team.length > 0){
        const usersName:string[] = [];
        for (let i = 0; i < team.length; i++) {
          usersName.push((await database.collection('users').doc(team[i]).get()).get('name') ?? '')
        }
        dialogContext.setState({
          show: true,
          title: 'Operadores confirmados',
          message: usersName.join('\n'),
          cancelButton: {
            text: 'fechar',
            callback: () => dialogContext.close()
          }
        })
      }else{
        addEnqueueSnackbar({
          variant: 'info',
          message: 'Nenhum operador confirmou presença'
        })
      }
    })
  }

  return (
    <Grid container sx={{marginTop: theme.spacing(1)}}>
      {!trainings.length && (
        <Grid item xs display='flex' justifyContent='center'>
          <Typography
            component='h2'
            variant='h4'
          >
            Não há treinos
          </Typography>
        </Grid>
      )}
      {trainings.map((training, index) => (
        <Grid key={index} item md={3} sm={4} xs={6}>
          <Card sx={{
            backgroundColor: training.cancelled ? theme.palette.error.dark : training.isConfirmed ? theme.palette.success.dark : undefined
          }}>
            <Grid container>
              <Grid item xs>
                <CardActionArea onClick={() => askConfirmation(training)} disabled={training.cancelled}>
                  <CardContent>
                    <Typography>{training.title}</Typography>
                    <Typography>{format(training.date, 'dd/MM/yyyy')}</Typography>
                  </CardContent>
                </CardActionArea>
              </Grid>
              <Grid item display='flex' alignItems='center'>
                <IconButton onClick={() => handleShowUsersConfirmed(training)}>
                  <Icons.People/>
                </IconButton>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      ))}
    </Grid>
  )
}
export default Dashboard
