import React, { useContext } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, DialogTitleProps, IconButton, styled } from '@mui/material';
import DialogContext from '../../context/DialogContext';
import * as Icons from '@mui/icons-material';

const DialogComp:React.FC = () => {
  const { state, setState } = useContext(DialogContext);
  const {
    show,
    title,
    message,
    onDismiss
  } = state;

  const confirmButton = {
    ...state.confirmButton,
    visible: state.confirmButton?.visible ?? Boolean(state.confirmButton)
  }
  const cancelButton = {
    ...state.cancelButton,
    visible: state.cancelButton?.visible ?? Boolean(state.cancelButton)
  }
  const thirdyButton = {
    ...state.thirdyButton,
    visible: state.thirdyButton?.visible ?? Boolean(state.thirdyButton)
  }

  const handlerHide = () => {
    setState(prev => ({
      ...prev,
      show: false
    }));
    setTimeout(() => {
      setState({
        show: false,
        title: '',
        message: ''
      })
    }, 190); // Time close of Dialog component default is 195ms
  }

  const DialogCustom = styled(Dialog)(({theme}) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))

  const DialogTitleCustom = (props: DialogTitleProps) => {
    const { children, ...other } = props;

    return (
      <DialogTitle
        {...other}
        sx={{
          paddingRight: 8,
          ...other.sx
        }}
      >
        {children}
        <IconButton
          onClick={() => onDismiss?.(handlerHide) ?? handlerHide()}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8
          }}
        >
          <Icons.Close/>
        </IconButton>
      </DialogTitle>
    )
  }

  return (
    <DialogCustom
      open={show}
      onClose={() => onDismiss?.(handlerHide) ?? handlerHide()}
    >
      <DialogTitleCustom>{title}</DialogTitleCustom>
      <DialogContent>{message}</DialogContent>
      <DialogActions>
        {cancelButton.visible && <Button onClick={() => {cancelButton?.callback?.(handlerHide) ?? handlerHide()}} {...cancelButton?.props}>{cancelButton?.text ?? 'Cancelar'}</Button>}
        {thirdyButton.visible && <Button onClick={() => {thirdyButton?.callback?.(handlerHide) ?? handlerHide()}} {...thirdyButton?.props}>{thirdyButton?.text ?? ''}</Button>}
        {confirmButton.visible && <Button onClick={() => {confirmButton?.callback?.(handlerHide) ?? handlerHide()}} {...confirmButton?.props}>{confirmButton?.text ?? 'Confirmar'}</Button>}
      </DialogActions>
    </DialogCustom>
  )
}
export default DialogComp
