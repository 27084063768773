import React, { createContext, useState } from "react";
import { DialogType } from "../types";

const DEFAULT_VALUE: DialogType = {
  show: false,
  title: ''
};

interface IDialogContext {
  state: DialogType
  setState: React.Dispatch<React.SetStateAction<DialogType>>
  close: () => void
}

const DialogContext = createContext<IDialogContext>({
  state: DEFAULT_VALUE,
  setState: () => {},
  close: () => {}
});

const DialogContextProvider: React.FC = ({ children }) => {
  const [state, setState] = useState<DialogType>(DEFAULT_VALUE);
  const close = () => setState({ show: false, title: '' });
  return (
    <DialogContext.Provider
      value={{state, setState, close}}
    >
      {children}
    </DialogContext.Provider>
  )
}

export { DialogContextProvider };
export default DialogContext;
