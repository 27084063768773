import { Theme } from "@mui/material";
import { makeStyles } from "@mui/styles";

export default makeStyles((theme: Theme) => ({
  titlePage: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: `${theme.spacing(1)} !important`
  },
  wrapper: {
    margin: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  buttonProgress: {
    position: 'absolute'
  },
  formControl: {
    marginTop: theme.spacing(2),
    width: '100%'
  },
  addressLabelSeparator: {
    textAlign: 'center',
    color: theme.palette.primary.dark,
    marginTop: theme.spacing(4)
  }
}));
