import React, { useContext, useEffect, useState } from 'react';
import { Box, Card, CardActionArea, CardContent, Grid, TextField, Typography, useTheme } from '@mui/material';
import { Training } from '../../types';
import { setLoading } from '../../utils/loadingState';
import { addMonths, isValid, lastDayOfMonth, setDate, subMonths } from 'date-fns';
import { auth, database, firebase } from '../../services/firebase';
import { addEnqueueSnackbar } from '../../components/Notifier';
import { DatePicker } from '@mui/lab';
import * as Icons from '@mui/icons-material';
import DialogContext from '../../context/DialogContext';
import style from './style';

interface _Training extends Training {
  present: boolean
}

const ListTrainings:React.FC = () => {
  const theme = useTheme();
  const classes = style(theme);
  const dialogContext = useContext(DialogContext);
  const [dateFilter, setDateFilter] = useState({
    initial: setDate(new Date(), 1),
    final: setDate(new Date(), lastDayOfMonth(new Date()).getDate())
  });
  const [dataGrid, setDataGrid] = useState<_Training[]>([]);

  useEffect(() => filter(), [dateFilter]); // eslint-disable-line

  const filter = () => {
    if (!isValid(dateFilter.initial) || !isValid(dateFilter.final)) return;
    setLoading(true);
    database
    .collection('tranningdates')
    .where('date', '>=', dateFilter.initial)
    .where('date', '<=', dateFilter.final)
    .get()
    .then(result => {
      setDataGrid(result.docs.map(item => ({
        date: new firebase.firestore.Timestamp(item.get('date').seconds, item.get('date').nanoseconds).toDate(),
        description: item.get('description'),
        title: item.get('title'),
        present: Boolean(item.get('users_confirmed')?.some?.((item:any) => item === auth.currentUser?.uid)),
        cancelled: item.get('cancelled'),
        doc: item.id
      })))
    }).catch(error => {
      addEnqueueSnackbar({
        variant: 'error',
        message: error.message
      })
    }).finally(() => setLoading(false));
  }

  const selectTraining = (training:_Training) => {
    database.collection('tranningdates').doc(training.doc).get().then(async result => {
      const users_confirmed = result.get('users_confirmed') ?? [];
      const participants:string[] = [];
      if(Array.isArray(users_confirmed) && users_confirmed.length > 0){
        await Promise.all(users_confirmed.map(async (item, index, arr) => {
          const userSelected = await database.collection('users').doc(item).get()
          participants.push(userSelected.get('name'))
        }))
      }
      dialogContext.setState({
        show: true,
        title: training.title,
        message: (
          <>
            <Typography variant='body2' component='p'>{training.description}</Typography>
            <Typography variant='body2' component='p' marginTop={1}>Participantes:</Typography>
            <ul className={classes.ul}>
              {participants.map((participant, index) => (
                <li key={index}>{participant}</li>
              ))}
            </ul>
          </>
        ),
        confirmButton: {
          visible: false
        },
        cancelButton: {
          text: 'fechar'
        }
      })
    })
  }

  return (
    <>
      <Grid container>
        <Grid item xs>
          <DatePicker
            value={dateFilter.initial}
            onChange={value => value && setDateFilter(prev => ({...prev, initial: value}))}
            renderInput={props => <TextField required {...props}/>}
            minDate={subMonths(new Date(), 4)}
            maxDate={new Date()}
            InputProps={{ readOnly: true }}
          />
        </Grid>
        <Grid item xs={1} alignItems='center' display='flex' justifyContent='center'>
          <Typography variant='subtitle2' component='p'>Até</Typography>
        </Grid>
        <Grid item xs>
          <DatePicker
            value={dateFilter.final}
            onChange={value => value && setDateFilter(prev => ({...prev, final: value}))}
            renderInput={props => <TextField required {...props}/>}
            minDate={new Date()}
            maxDate={addMonths(new Date(), 4)}
            InputProps={{ readOnly: true }}
          />
        </Grid>
      </Grid>
      <Grid container marginTop={1}>
        {dataGrid.map((item, index) => (
          <Grid item key={index} xs={12} sm={6} lg={3}>
            <Box>
              <Card variant='outlined'>
                <CardActionArea onClick={() => selectTraining(item)}>
                  <CardContent>
                    <Typography variant='body1' component='div' display='flex' alignItems='center'>
                      {item.present && <Icons.CheckCircle sx={{marginRight: 1}} color='success'/>}
                      {!item.present && <Icons.Close sx={{marginRight: 1}} color='error'/>}
                      {item.title}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  )
}
export default ListTrainings
